body {
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

body::-webkit-scrollbar{
  display: none;
}

html {
  background-color: #2e3440;
}

#root {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

#dvdlogo {
  display: block;
  position: absolute;
  z-index: 1;
  -webkit-animation: moveX 10s linear 0s infinite alternate, moveY 17s linear 0s infinite alternate, changeColour 30s, linear, 0s, infinite, normal, none, infinite;
  -moz-animation: moveX 10s linear 0s infinite alternate, moveY 17s linear 0s infinite alternate, changeColour 30s, linear, 0s, infinite, normal, none, infinite;
  -o-animation: moveX 10s linear 0s infinite alternate, moveY 17s linear 0s infinite alternate, changeColour 30s, linear, 0s, infinite, normal, none, infinite;
  animation: moveX 10s linear 0s infinite alternate, moveY 17s linear 0s infinite alternate, changeColour 30s, linear, 0s, infinite, normal, none, infinite;
  animation-iteration-count:infinite;
}
svg {
  display: block;
}
@-webkit-keyframes moveX {
  from { left: 0; } to { left: calc(100vw - 153px); }
}
@-moz-keyframes moveX {
  from { left: 0; } to { left: calc(100vw - 153px); }
}
@-o-keyframes moveX {
  from { left: 0; } to { left: calc(100vw - 153px); }
}
@keyframes moveX {
  from { left: 0; } to { left: calc(100vw - 153px); }
}

@keyframes changeColour {
  0% { fill: #ff6969; }
  14% { fill: #fd9644; }
  28% { fill: #fed330; }
  42% { fill: #2dc22d; }
  56% { fill: #45d8f2; }
  70% { fill: #5e6cea; }
  84% { fill: #c22dc2; }
  100% { fill: #ff6969; }
}

@-webkit-keyframes moveY {
  from { top: 0; } to { top: calc(100vh - 69px); }
}
@-moz-keyframes moveY {
  from { top: 0; } to { top: calc(100vh - 69px); }
}
@-o-keyframes moveY {
  from { top: 0; } to { top: calc(100vh - 69px); }
}
@keyframes moveY {
  from { top: 0; } to { top: calc(100vh - 69px); }
}

.row-container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  overflow: hidden;
}

.fillRow {
  flex-grow: 1;
  border: none;
  margin: 0;
  padding: 0;
}

.container {
  width: 100%;
  margin: 0 auto;
}

.flex-parent {
  display: flex;
}

.flex-grow {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0%;
}

.flex-item {
  padding: .5em;
  flex: 1;
}
